
@import "../../../../colors.scss";

.newsMainBlock {
  margin-bottom: 16px;
  margin-top: 32px;

  h2 {
    font-size: 24px;
  }

  .card {
    text-align: center;
    padding: 24px;

    .date {
      margin-bottom: 24px;
      color: $gray;
    }
    .text {
      font-size: 16px;
    }
  }

  .react-multi-carousel-list {
    width: 100%;
  }
}

.newsPack {

  .ca-menu {
    width: 200px;
    height: 220px;
    margin-top: 20px;
    cursor: pointer;
    // box-shadow: 0 1px 4px rgb(0 0 0), -23px 0 20px -23px rgb(0 0 0 / 80%), 23px 0 20px -23px rgb(0 0 0 / 80%), 0 0 40px rgb(0 0 0 / 10%) inset;
    li {
        width: 200px; height: 220px; margin:0;
    }

    .ca-icon {
      background: url('../../../../assets/upac.png') no-repeat;
    }
  }

  svg {
    margin-left: -4px;
  }

}