.mainSlab {
  background: url('../../assets/topbaner.jpg') no-repeat;
  background-position-y: 112px;
  background-size: cover;
  height: 760px;
  box-sizing: border-box;
  min-height: 760px;

  .waterMark {
    height: 90%;
    width: 100%;
    background: url('../../assets/vertical_logo.svg') no-repeat;
    background-size: contain;
  }

  .titleRow {
    text-align: right;
    padding-top: 2rem;
    padding-bottom: 1rem;
    
    h1 {
      font-size: 42px;
      line-height: 40px;
      font-weight: 400;
    }
    
    .hilight {
      color: red;
      font-weight: 600;
      font-size: 52px;
      line-height: 52px;
      text-shadow: 1px 1px 2px black, 0 0 1em rgb(48, 48, 48); /* Параметры тени */
      display: block;
    }
  }
}

.searchBlock {
  background: #FFFFFFA0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1),
    -23px 0 20px -23px rgba(0, 0, 0, .8),
    23px 0 20px -23px rgba(0, 0, 0, .8),
    0 0 40px rgba(0, 0, 0, .1) inset;
  padding: 40px;
  margin-left: auto;
  width: 520px;

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }

  button {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
}