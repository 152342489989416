.aboutMainBlock {
  margin-bottom: 16px;
  margin-top: 32px;

  h2 {
    font-size: 24px;
  }

  .poster {
    background: url('../../../../assets/aboutbanlo.jpg');
    width: 200px;
    height: 554px;
    background-position: center;
    background-size: cover;
  }
}