
@import "../../../../colors.scss";

.mainSlub {
  padding: 32px 0px 24px 0px;
  border: none;
  display: flex;

  h2 {
    padding: 0;
    margin: 0;
  }

  h3 {
    padding: 0;
    margin: 0;
  }

  .part1 {
    background: url('../../../../assets/parts_1.png') no-repeat;
  }

  .part2 {
    background: url('../../../../assets/parts_2.png') no-repeat;
  }

  .part3 {
    background: url('../../../../assets/parts_3.png') no-repeat;
  }

  .part4 {
    background: url('../../../../assets/parts_4.png') no-repeat;
  }

  .part5 {
    background: url('../../../../assets/parts_5.png') no-repeat;
  }
}

.modalSlub {
  .body {
    min-width: 626px !important;
    background: url('../../../../assets/fon_mousson.png');
  }
  
  .part1 {
    background: url('../../../../assets/PISTON.jpg') no-repeat;
    border: 4px solid gray;
    width: 622px;
    height: 330px
  }

  .part2 {
    background: url('../../../../assets/SLEEVE.jpg') no-repeat;
    border: 4px solid gray;
    width: 622px;
    height: 330px
  }

  .part3 {
    background: url('../../../../assets/PAD.jpg') no-repeat;
    border: 4px solid gray;
    width: 622px;
    height: 330px
  }

  .part4 {
    background: url('../../../../assets/CHAIN.jpg') no-repeat;
    border: 4px solid gray;
    width: 622px;
    height: 330px
  }

  .part5 {
    background: url('../../../../assets/SHAFT.jpg') no-repeat;
    border: 4px solid gray;
    width: 622px;
    height: 330px
  }
}