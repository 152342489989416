
@import "../../../../colors.scss";

.RelatedRow {
  display: flex;
  gap: 8px;
  margin-top: 20px;
  position: relative;

  .arrowLeft {
    left: -50px;
    top: 73px;
    height: 60px;
    width: 46px;
    background: url('../../../../assets/arrow-left.svg');
    background-size: cover;
    cursor: pointer;
    background-repeat: no-repeat;
    position: absolute;
    &:hover {
      filter: invert(42%) sepia(93%) saturate(1528%) hue-rotate(329deg) brightness(84%) contrast(172%);
     }
  }

  .arrowRight {
    right: -40px;
    top: 73px;
    height: 60px;
    width: 46px;
    background: url('../../../../assets/arrow-right.svg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    &:hover {
      filter: invert(42%) sepia(93%) saturate(1528%) hue-rotate(329deg) brightness(84%) contrast(172%);
    }
  }


  
}

.RelatedSlab {
  background: $lightGray;
  border: 1px solid $gray;
  margin-bottom: 20px;
  width: 230px;
  padding: 8px;
  
  .image {
    width: 100%;
    background: url('../../../../assets/placeholder.jpg');
    height: 136px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
  }

  button {
    width: 100%;
  }

  .article {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }

  .name {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px;
    font-weight: 500;
  }
}
