
@import "../../../../colors.scss";

.requestForm {
  margin-bottom: 12px;
  margin-top: 32px;
  background: #FFFFFFA0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1),
    -23px 0 20px -23px rgba(0, 0, 0, .8),
    23px 0 20px -23px rgba(0, 0, 0, .8),
    0 0 40px rgba(0, 0, 0, .1) inset;

  .title {
    background: url('../../../../assets/botbanner.jpg');
    color: white;
    font-size: 24px;
    padding: 8px 24px;
  }

  .content {
    padding:24px;

    .grid {
      align-items: end;
    }
  }

  label {
    display: block;
  }

  input {
    width: 100%;
  }

  button {
    width: 100%;
    margin-bottom: 8px;
  }

  .required {
    &:after {
      content: "*";
      color: red;
    }
  }
}