
@import "../../../../colors.scss";

.order {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.orderRow {
  display: flex;
  border-bottom: 1px solid #A6A6A6;
  padding-bottom: 8px;
  justify-content: flex-end;

  .cell {
    padding-left: 2rem;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
  }
}


.detailRow {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #a6a6a670;
  border-bottom: 1px solid #858585;

  .cell {
    flex: 1;
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
    
  }

  .cellFully {
    flex-basis: 50%;
    padding-left: 8px;
    padding-right: 8px;
  }
}