.mainSearchBlock {
  background: #FFFFFFA0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1),
    -23px 0 20px -23px rgba(0, 0, 0, .8),
    23px 0 20px -23px rgba(0, 0, 0, .8),
    0 0 40px rgba(0, 0, 0, .1) inset;
  padding: 40px;
  margin-left: auto;
  width: 520px;

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }

  button {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
}

.pageSearchBlock {
  display: flex;
  align-items: center;
  margin-top: 16px;

  >div:first-child {
    flex-basis: 100%;
  }

  >div:last-child {
    flex-basis: 90%;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }

  button {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
}