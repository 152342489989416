
.radio {

  .row {
    display: flex;
    margin-bottom: 1rem;
  }

  .title {
    color: red;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  label {
    cursor: pointer;
  }

  .point {
    width: 16px;
    height: 16px;
    border: 3px solid white;
    background: gray;
    border-radius: 100%;
    margin-right: 1rem;
    cursor: pointer;
  }

  .selected {
    .point {
      background: red;
    }
  }

}