@import "../../colors.scss";

.basket {
  .x4 {
    width:40%;
    padding-right: 12px;
  }

  .x6 {
    width: 60%;
  }

  .order {

    padding: 4px 14px 8px 14px;
    border: 1px solid $gray;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    background: $lightGray;
    margin-bottom: 14px;

    .highlight {
      margin-left: 6px;
      margin-right: 6px;
      display: inline-block;
      font-weight: 600;
    }

    .amount {
      margin-left: 6px;
      margin-right: 6px;
      display: inline-block;
      color: $red;
      font-size:24px;
    }
  }

  .form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .label {
      display: block;
      margin-bottom: 12px;
    }

    select {
      width: 100%;
      padding: 8px;
    }

    textarea {
      width: 100%;
      height: 140px;
      padding: 12px;
      box-sizing: border-box;
    }
  }


  .controls {
    display: flex;
    justify-content: space-between;

    button {
      width: 100%;
    }
  }
}