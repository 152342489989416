.mainMenu {
  background: white;
  position: absolute;
  margin: auto;
  max-width: 1024px;
  width: 1024px;
  box-sizing: border-box;
  left: 0;
  right: 0;
  padding: 2rem 2rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1),
    -23px 0 20px -23px rgba(0, 0, 0, .8),
    23px 0 20px -23px rgba(0, 0, 0, .8),
    0 0 40px rgba(0, 0, 0, .1) inset;

  display: flex;

  .logoWrap {
    display: flex;
    align-items: center;

    .logo {
      height: 60px;
      width: 180px;
      background: url('../../assets/headerlogo.svg');
      background-repeat: no-repeat;
      margin-right: 1rem;
    }

    .desc {
      color: gray;
    }
  }

  .menuWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .linkItem {
      cursor: pointer;
      margin-left: 1rem;
      margin-right: 1rem;
      color: #333;
      font-weight: 500;
      font-size: 18px;
      &.active {
        color: red;
      }
      &:hover {
        color: red;
      }
    }

    .imageItem {
      width: 50px;
      height: 50px;
      margin-left: 1rem;
      margin-right: 1rem;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .basket {
      background-image: url('../../assets/basket_1.svg');
      background-position-y: 5px;
      position: relative;
      
      &.active {
        background-image: url('../../assets/basket_2.svg');
      }

      &:hover {
        background-image: url('../../assets/basket_2.svg');
      }

      .count {
        position: absolute;
        left: 0px;
        right: 0px;
        text-align: center;
        top: -14px;
        margin-left: 4px;
        color: red;
      }
    }

    .entrance {
      cursor: pointer;
      background-image: url('../../assets/entrance_1.svg');
      &:hover {
        background-image: url('../../assets/entrance_2.svg');
      }
    }

    .exit {
      cursor: pointer;
      background-image: url('../../assets/exit_1.svg');
      &:hover {
        background-image: url('../../assets/exit_2.svg');
      }
    }

    .exit {
      background-image: url('../../assets/exit_1.svg');
      &:hover {
        background-image: url('../../assets/exit_2.svg');
      }
    }
  }

}

