
@import "../../../../colors.scss";

.SparepartSlab {
  display: flex;
  background: $lightGray;
  border: 1px solid $gray;
  margin-top: 20px;

 
  .colum {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
  }

  .subtitle {
    font-size: 18px;
    margin-left: 12px;
    margin-top: 12px;

    span {
      font-weight: 600;
    }
  }

  .image {
    width: 360px;
    height: 202px;
    background: url('../../../../assets/placeholder.jpg');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;

    margin-bottom: 8px;
  }

  .video {
    width: 360px;
    height: 202px;
    margin-bottom: 8px;
  }

  .attrRow {
    padding-bottom: 10px;
    font-size: 14px;

    span {
      font-weight: 600;
      padding-right: 8px;
    }

    .highlight {
      color: $red;
      display: inline;
    }
  }

  .pushRow {
    flex: 1;
  }

  input {
    width: 120px;
    margin-right: 4px;
  }

  button {
    width: 100%;
  }

  
}

.zoomImageModal {
  .body {
    max-width: 840px
  }
}
