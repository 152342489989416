.contacts {
  p {
    padding-left: 12px;
    div {
      line-height: 24px;
    }
  }

  .row {
    display: flex;
    .content{
      flex:1;
    }
    .waterSign {
      width: 300px;
      height: 600px;
      position: relative;

      &:before {
        content: "";
        width: 600px;
        height: 300px;
        position: absolute;
        top: 165px;
        left: -96px;
        transform: rotate(90deg);
        background: url('../../assets/footerlogo.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 0.4;
        
      }
    }
  }
}