@import '~grid.sass';
@import "./assets/css/normalize/normalize";
@import "./colors.scss";

html{
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./assets/fon_mousson.jpg');
  background-attachment: fixed;
  margin: 0;
  background-size: cover;
  height: 100%;
}

fieldset {
  padding: 0;
  border: none;
}

a {
  text-decoration: none;
}

input {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;

  &.error {
    border-color: $red;
  }
}


button {
  border: none;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 16px;

  &.default {
    background: url('./assets/botbanner.jpg');
    &:hover {
      background: none;
      background-color: $red;
    }
  
    &:disabled {
      cursor: default;
      opacity: 0.5;
      background: url('./assets/botbanner.jpg');
    }
  }

  &.accent {
    background: $red;

    &:hover {
      background: $darkGray;
    }
  
    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}


#root {
  height: 100%;
}

.flex {
  display: flex;
}

.fullHeigh {
  height: 100%;
}

.mainContainer {
  width: 1024px;
  box-sizing: border-box;
  margin: auto;
  padding-top: 128px;
  min-height: calc(100% - 146px);
}

.container {
  width: 1024px;
  box-sizing: border-box;
  margin: auto;
}

.quite {
  color: gray;
}

.highlight {
  color: red;
}

.link {
  color: red;
  cursor: pointer;
}

.rightOffset {
  margin-right: 8px;
}

.leftOffset {
  margin-left: 8px;
}






























.ca-menu{
  padding:0;
  margin:20px auto;

  li {
    width: 180px;
    height: 300px;
    overflow: hidden;
    position: relative;
    float:left;
    margin-left:8px;
    margin-right:16px;
    background: #f9f9f9; 
    background: -moz-linear-gradient(left, #ffffff 0%, #f6f6f6 47%, #ededed 100%); 
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ffffff), color-stop(47%,#f6f6f6), color-stop(100%,#ededed));  
    background: -webkit-linear-gradient(left, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
    background: -o-linear-gradient(left, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
    background: -ms-linear-gradient(left, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
    background: linear-gradient(left, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=1 ); 
    -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
  
    a {
      text-align: left;
      width: 100%;
      height: 100%;
      display: block;
      color: #FFFFFF;
      position: relative;
      text-shadow: 1px 1px 2px black;
    }
  
  }

  li:last-child{
    margin-right: 0px;
  }

  li:hover{
    background:#FFFFFF;
  }
  li:hover .ca-icon{
    color: #FF0000;
    font-size: 150px;
    font-weight:400;
    opacity: 0.5;
    // -webkit-animation: moveFromLeft 400ms ease;
    // -moz-animation: moveFromLeft 400ms ease;
    // -ms-animation: moveFromLeft 400ms ease;
  }
  li:hover .ca-main{
    color: #FF0000;
    -webkit-animation: moveFromRight 300ms ease;
    -moz-animation: moveFromRight 300ms ease;
    -ms-animation: moveFromRight 300ms ease;
  }

  li:hover .ca-sub{
    color: #000000;
    text-shadow:none;
    -webkit-animation: moveFromBottom 500ms ease;
    -moz-animation: moveFromBottom 500ms ease;
    -ms-animation: moveFromBottom 500ms ease;
  }


  .ca-icon{
    line-height: 150px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    text-align: center;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .ca-content{
      position: absolute;
      left: 0px;
      width: 100%;
      height: 50%;
      top: 50%;
  }

  .ca-main{
      font-size: 30px;
      opacity: 0.8;
      text-align: center;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      transition: all 200ms linear;
  }
  .ca-sub{
      text-align:center;
      font-size: 14px;
      color: #FF0000;
      line-height: 40px;
      opacity: 0.8;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      transition: all 200ms linear;
  }
}






@keyframes moveFromLeft{
  from {
      -webkit-transform: translateX(-100%);
  }
  to {
      -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes moveFromLeft{
  from {
      -moz-transform: translateX(-100%);
  }
  to {
      -moz-transform: translateX(0%);
  }
}

@keyframes moveFromRight {
  from {
      -webkit-transform: translateX(100%);
  }
  to {
      -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes moveFromRight {
  from {
      -moz-transform: translateX(100%);
  }
  to {
      -moz-transform: translateX(0%);
  }
}

@keyframes moveFromBottom {
  from {
      -webkit-transform: translateY(100%);
  }
  to {
      -webkit-transform: translateY(0%);
  }
}
@-moz-keyframes moveFromBottom {
  from {
      -moz-transform: translateY(100%);
  }
  to {
      -moz-transform: translateY(0%);
  }
}

