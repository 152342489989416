
@import "../../colors.scss";

.line {
  border-bottom: 1px solid $gray;
  position: relative;
  
  h1 {
    font-weight: 600;
    line-height:44px;
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
    padding-left: 12px;
  }

  h2 {
    font-weight: 600;
    line-height:34px;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 12px;
    padding-left: 12px;
  }


  h3 {
    font-weight: 600;
    line-height:24px;
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &.div {
    font-weight: 600;
    line-height:24px;
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  &.redline:before {
    content: "";
    position: absolute;
    width: 274px;
    height: 4px;
    background:$red;
    bottom: -4px;
    left: 0;
  }
}