@import "../../../../colors.scss";

.authModal {
  .title {
    line-height: 26px;
    font-size: 28px;
  }

  .form {
    margin-bottom: 12px;

    .field {

    }

    .label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
    }

    input {
      width: 100%;
    }
  }

  .rememberLink {
    color: $red;
    margin-bottom: 12px;
  }

  button {
    width: 100%;
    margin-top: 12px;
  }
}

